<template>
<!--  <div class="home" @mousewheel="test">-->
  <div class="home">
    <top-page @cli="cli"  ></top-page>
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src


import TopPage from "@/components/topPage";
// import LunboPage from "@/components/lunboPage";
export default {
  name: 'HomeView',
  components: {
    // LunboPage,
    TopPage
  },
  data(){
    return{
      he:1,
    }
  },
  methods:{
    cli(val){
      switch (val) {
        case 0 :
          this.$router.push({
            path:'/index'
          })
              break
        case 1:
          this.$router.push({
            path:'/courseCenter'
          })
              break
        case 2:
          this.$router.push({
            path:'/onlineTest'
          })
              break
        case 3:
          this.$router.push({
            path:'/onlinePractice'
          })
              break
        case 4:
          this.$router.push({
            path:'/cooperationCases'
          })
              break
        case 5:
          this.$router.push({
            path:'/appDownload'
          })
              break
        case 6:
          this.$router.push({
            path:'/contactUs'
          })
              break
      }
    },
    // test(e) {//配合@mousewheel使用
    //   console.log(e.wheelDelta)//当此数为负数时候，是向下滚动；正数时为向上滚动
    // }
  },
  mounted() {
  }
}
</script>
<style scoped>
.home{
  height: 100%;
  width: 100%;
  min-width: 1515px;
  min-height: 630px;
  display: flex;
  flex-direction: column;
}
</style>
