import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)
// 解决报错
const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
VueRouter.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // 重定向主页页面
    redirect:"/index",
    children:[
      {//首页
        path:'/index',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/homePage'),
        meta: { title: '首页',permission: 'index'  },
      },
      {//课程中心
        path:'/courseCenter',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/courseCenterPage'),
        meta: { title: '课程中心',permission: 'courseCenter'  },
        children:[
        ]
      },
      {//课程中心二级页面
        path:'/courseCenter/courseCenterSon',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/courseCenter/courseCenterSon'),
        meta: { title: '课程中心',permission: 'courseCenter'  },
      },
      {//课程章节测试题目内容页页面
        path:'/courseCenter/toCourseTest',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/courseCenter/courseTestBody'),
        meta: { title: '章节测试',permission: 'courseCenter'  },
      },
      {//课程章节测试结果页
        path:'/courseCenter/toCourseTestResult',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/courseCenter/courseTestResultsPage'),
        meta: { title: '章节测试',permission: 'courseCenter'  },
      },
      {//在线测试
        path:'/onlineTest',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/onlineTestPage'),
        meta: { title: '在线考试',permission: 'onlineTest'  },
      },
      {//在线测试
        path:'/onlineTest/testDetailsBody',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlineTest/testDetailsBody'),
        meta: { title: '在线考试',permission: 'onlineTest'  },
      },
      {//在线测试详情
        path:'/onlineTest/onlineTestDetail',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlineTest/testDetailsPage'),
        meta: { title: '在线考试',permission: 'onlineTest'  },
      },
      {//在线测试成绩
        path:'/onlineTest/onlineTestResult',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlineTest/testResultsPage'),
        meta: { title: '在线考试',permission: 'onlineTest'  },
      },
      {//在线练习 题库
        path:'/onlinePractice',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/onlinePracticePage'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      {//在线练习 题目内容页 题库
        path:'/onlinePractice/toPractice',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlinePractice/practiceBankBody'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      // {//在线练习结果页 题库
      //   path:'/onlinePractice/toGainBankResult',
      //   component:() =>import(/* webpackChunkName: "views" */ '@/components/onlinePractice/practiceBankResultsBody'),
      // },
      {//在线练习重测结果页 题库
        path:'/onlinePractice/toReBankResult',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlinePractice/practiceResultsPage'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      {//在线练习 题目内容页 练习题
        path:'/onlinePractice/toExercises',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlinePractice/practiceExercisesBody'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      {//在线练习 结果页 练习题
        path:'/onlinePractice/toCardResult',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlinePractice/practiceExercisesResultsPage'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      {//个人中心
        path:'/personalCenter',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/personalPage'),
        meta: { title: '个人中心',permission: 'personalCenter'  },
      },
      {//合作案例
        path:'/cooperationCases',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/cooperationCases'),
        meta: { title: '我们优势',permission: 'cooperationCases'  },
      },
      {//APP下载
        path:'/appDownload',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/appDownload'),
        meta: { title: 'APP下载',permission: 'appDownload'  },
      },
      {//联系我们
        path:'/contactUs',
        component:() =>import(/* webpackChunkName: "views" */ '@/views/contactUs'),
        meta: { title: '联系我们',permission: 'contactUs'  },
      },
      {//搜索结果页
        path:'/search',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/searchPage'),
        meta: { title: '搜索结果',permission: 'search'  },
        children: [
          {//搜索结果页
            path:'/searchSon',
            component:() =>import(/* webpackChunkName: "views" */ '@/components/searchSon/questionBank'),
            meta: { title: '搜索结果',permission: 'search'  },
          },
        ]
      },

      {
        path:'/buy',
        name:'buy',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/buyPage'),
      },
      {
        path:'/toAliPay',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/aliPayPage'),
      },
      {
        path:'/toWxPay',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/orderPay/wxPay'),
      },
      {// 题库附加页面
        path:'/testIP',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/additionalPages/testIntroductionPage'),
        meta: { title: '在线考试',permission: 'onlineTest'  },
      },
      {// 在线练习附加页面
        path:'/testOnlineIP',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/additionalPages/onlinePracticeIntroduction/testIntroductionPage'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      {// 在线练习查看解析
        path:'/toViewR',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/onlinePractice/viewResolution/index'),
        meta: { title: '在线练习',permission: 'onlinePractice'  },
      },
      {// 课程章节测试介绍
        path:'/courseChapterTestInfo',
        component:() =>import(/* webpackChunkName: "views" */ '@/components/additionalPages/courseChapterTestInfo/testIntroductionPage'),
        meta: { title: '课程测试',permission: 'courseCenter'  },
      },
      {//章节测试做题页面
        path: '/toSectionTesting',
        component:()=>import(/* webpackChunkName: "views" */ '@/components/courseCenter/sectionTesting/sectionTesting'),
        meta: { title: '课程测试', permission: 'courseCenter' }
      },
      {//章节测试答题卡
        path: '/toAnswerSheet',
        component:()=>import(/* webpackChunkName: "views" */ '@/components/courseCenter/sectionTesting/answerSheet/index'),
        meta: { title: '课程测试', permission: 'courseCenter' }
      },
      {//课程章节测试查看解析
        path: '/toCourseViewR',
        component:()=>import(/* webpackChunkName: "views" */ '@/components/courseCenter/sectionTesting/answerSheet/courseViewAnalysis'),
        meta: { title: '课程测试', permission: 'courseCenter' }
      },
    ]
  },

]

const router = new VueRouter({
  mode:"history",
  routes
})

export default router
